import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle  } from 'styled-components';
import fotoBonita from "../../assets/img/AYH/AYHBoat.jpg";
import fotoAmazon from "../../assets/img/AYH/amazon.png";
import fotoCarnival from "../../assets/img/AYH/carnival.png";
import Fondo from "../../assets/img/AYH/flores.png";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');
`;

const Wrapper = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

  background-image: url(${Fondo});
  background-size: auto; /* Permite que la imagen se repita en su tamaño original */
  background-repeat: repeat; /* Hace que la imagen de fondo se repita */
  background-position: center; /* Centra la imagen de fondo */
  background-color: #fdf3ea;
  display: grid;
  place-items: center;
`;

const StyledSection = styled.section`
  @import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

  margin-top: 0;
  width: 100%; /* Asegura que la sección ocupe todo el ancho del contenedor */
  background-color: transparent; /* Fondo transparente para mostrar el fondo del envolvente */
  display: grid;
  place-items: center;
  padding: 20px; /* Asegura que el contenido no esté pegado a los extremos */

`;

const CenteredText = styled.section`
  font-size: 6rem;
  color: #316d98;
  text-align: center;
  font-family: "La Belle Aurore", cursive;
  padding-botton: 20px;
  padding-top: 40px;
`;

const TextSecondary = styled.section`
  font-size: 2rem;
  color: #316d98;
  text-align: center;
  font-family: "Reenie Beanie", cursive;
`;

const StyledImage = styled.img`
  max-width: 90vw;  // Ajusta el ancho máximo al 90% del viewport width
  max-height: 90vh; // Ajusta la altura máxima al 90% del viewport height
  width: auto;
  height: auto;
  object-fit: contain; // Asegura que la imagen se ajuste sin distorsión
  padding-top: 40px;

  @media (max-width: 768px) {
    max-width: 100%;  // Ajusta el ancho máximo al 100% del viewport width
    max-height: 100%; // Ajusta la altura máxima al 100% del viewport height
    padding-top: 20px;  // Ajusta el padding superior en pantallas más pequeñas
    width: 80%;
  }

  @media (max-width: 480px) {
    max-width: 100%;  // Ancho máximo del 100% en pantallas muy pequeñas
    max-height: 100%; // Altura máxima del 100% en pantallas muy pequeñas
    padding-top: 10px;  // Ajusta el padding superior en pantallas muy pequeñas
    width: 80%;
  }
`;

const TextVerse = styled.section`
  font-size: 1.8rem;
  color: #316d98;
  text-align: center;
  font-family: "Alice", serif;
  padding-top: 40px; 
`;

const TextMesa = styled.section`
  font-size: 2rem;
  color: #316d98;
  text-align: center;
  font-family: "La Belle Aurore", cursive;
  padding-top: 40px; 
`;

const DividerSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  gap: 40px; // Espacio entre el texto y el separador
  text-align: center;
  padding-top: 40px; 
  font-family: "Reenie Beanie", cursive;
`;

const TextBlock = styled.div`
  font-size: 2rem;
  color: #316d98;
  width: 300px; // Limita el ancho de cada bloque de texto
  word-wrap: break-word; // Permite que el texto se ajuste a dos renglones si es necesario
`;

const Separator = styled.div`
  width: 4px;  // Ancho del separador
  height: 100px; // Altura del separador
  background-color: #316d98; // Color del separador
`;


const Button = styled.button`
  padding-top: 40px; 
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 2rem;
  color: #fff;
  background-color: #adb2da; // Color de fondo del botón
  border: 5px solid #adb2da; // Borde de 5px
  border-radius: 15px; // Esquinas redondeadas
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-family: "Reenie Beanie", cursive;

  a {
    color: white;
}
  &:hover {
    background-color: #316d98; // Color de fondo cuando se pasa el ratón
    border-color: #316d98; // Color del borde cuando se pasa el ratón
  }
`;

const ImagesSection = styled.div`
  display: grid;
  width: 50%;
  max-width: 80vw; // Limita el ancho total de las imágenes
  height: 30vh; // Altura total de las imágenes
  @media (max-width: 768px) {
    grid-template-columns: 1fr; // Una columna en pantallas pequeñas
    height: auto; // Ajusta la altura automáticamente en pantallas pequeñas
  }
`;

const Image = styled.img`
  width: 100%; // Ocupa el 100% del ancho de su columna
  height: 100%; // Ocupa el 100% de la altura del contenedor
  object-fit: cover; // Ajusta la imagen para cubrir el contenedor sin distorsión
`;

const ImageLink = styled.a`
  display: block; // Asegura que el enlace ocupe el espacio completo del contenedor
  width: 100%; // Ocupa el 100% del ancho de su columna
  height: 100%; // Ocupa el 100% de la altura del contenedor
`;

const Top = () => {
    const countdownDate = new Date("2025-10-12T00:00:00").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const distance = countdownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  return (
    <Wrapper>
      <StyledSection>
        <GlobalStyle />
        <CenteredText>
            alejandro & hannia
            <TextSecondary>
                Octubre 12, 2025, Costa Mujeres, Quintana Roo, México
            </TextSecondary>
            <TextSecondary>
                ¡Faltan {timeLeft.days} dias!
            </TextSecondary>
        </CenteredText>
        <StyledImage src={fotoBonita} alt="Foto muy bonita de una pareja enamorada" />
            <TextVerse>
            <p>
            “Y sobre todas estas cosas vestíos de amor, que es el vínculo perfecto.”
            </p>
            <p>
            Colosenses 3:14
            </p>
            </TextVerse>
        <DividerSection>
          <TextBlock>Octubre 12, 2025</TextBlock>
          <Separator />
          <TextBlock>Grand Palladium, Costa Mujeres, Quintana Roo</TextBlock>
        </DividerSection>
        <Button><a href='https://bit.ly/BODAHANNIAYALEJANDRO' target="_blank" rel="noopener noreferrer">Reserva tu viaje aquí</a></Button>
        <TextMesa>Mesa de Regalos</TextMesa>
        <ImagesSection>
        <ImageLink href="https://www.amazon.com.mx/wedding/share/hanniandalex
" target="_blank" rel="noopener noreferrer">
            <Image src={fotoAmazon} alt="Link de Amazon" />
        </ImageLink>
        {/* <ImageLink href="https://carnival.honeymoonwishes.com/Honeymoon-Registry-454192-Carnival-Vista-Hannia-Mata-Alejandro-Sanchez.html" target="_blank" rel="noopener noreferrer">
            <Image src={fotoCarnival} alt="Link de Carnival" />
        </ImageLink> */}
        </ImagesSection>
      </StyledSection>
      </Wrapper>
  );
};

export default Top;
